@import "./src/styles";

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      max-width: 100%;
      height: 40px;
    }
  }
}
