@import './src/styles';

.data-table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0;
  line-height: 24px;
  width: 100%;
  
  select {
    border: none;
    border-bottom: 1px dotted $data-table-border-color;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
  }
  
  button {
    @include table-button;
  }
  
  &__page-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  &__per-page-selector {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
  }
  
  @media (max-width: 767px) {
    padding: 16px;
  }
  
  @media (max-width: 375px) {
    &__per-page-selector {
      margin-bottom: 0;
    }
  
    &__page-selector {
      width: 100%;
    }
    
    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}