@import './src/styles';

.tools-and-apps {
  margin-top: 24px;
  
  &__section-title {
    font-size: 18px;
    color: $primary-text-color;
    margin-bottom: 24px;
  }
  
  .information-block {
    padding: 32px 16px;
    width: 340px;
  }
}