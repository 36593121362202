@import './src/styles';

.panel {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: $secondary-background-color;
  padding: 20px 35px;
  font-size: 14px;
  line-height: 18px;
  box-shadow: $box-shadow;
  
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $primary-background-color;
    margin-bottom: 24px;
    padding: 0 0 16px;
    width: 100%;
  }

  .name {
    font-size: 21px;
    line-height: 27px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    
    svg {
      fill: $error-color;
      margin-left: 4px;
      position: relative;
      height: 14px;
      width: 14px;
      top: 1px;
    }
  }
  
  @media (max-width: 480px) {
    padding: 20px;
  }
}