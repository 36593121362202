@import './src/styles';

.ai-key-personal-info {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 21px;
  color: $primary-text-color;
  
  &__paragraph {
    margin: 0;
    padding: 0;
  }
  
  b {
    font-weight: bold;
    color: $brand-color;
    cursor: pointer;
  }
}