@import "./src/styles";

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

#root,
.main-container {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: Lettera, Inter, Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.menu-opened {
    overflow-y: hidden;
  }

  * {
    box-sizing: border-box;

    .row {
      display: flex;
      align-items: center;
      &.centered {
        justify-content: center;
      }
    }

    .ant-pagination {
      .ant-pagination-item {
        border-color: $btn-background-color !important;

        a {
          color: $brand-color;

          &:hover {
            color: $btn-hover-background-color;
            border-color: $btn-hover-background-color !important;
          }
        }
      }

      .ant-pagination-item-active {
        background: $btn-hover-background-color;
        border-color: $secondary-background-color;

        a {
          color: $secondary-background-color;
        }
      }
    }

    .ant-table-thead > tr > th.ant-table-cell {
      color: $table-th-color;
      border-radius: unset;

      &::before {
        display: none;
      }
    }

    button {
      &.ant-btn.ant-btn-primary {
        background: $btn-background-color;
        color: $secondary-text-color;
        box-shadow: none;

        &:hover {
          background: $btn-hover-background-color;
        }
      }
    }

    .ant-radio-wrapper {
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: $brand-color;
          background-color: $brand-color;
        }
      }
    }

    .ant-modal-confirm {
      .ant-modal-confirm-btns {
        .ant-btn {
          &.cancel-button {
            background: $error-color;
          }
        }
      }
    }

    .ant-picker {
      width: 140px;
    }
  }

  .logout-button {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .ant-tooltip {
    .ant-tooltip-inner {
      padding: 15px 20px;
      color: $primary-text-color;
    }
  }

  .fetching {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }

    .spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  .notification-manager + div {
    transition: all 0.3s ease;
  }

  .settings-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        color: $secondary-background-color;
      }
    }
  }

  .ant-tag {
    cursor: pointer;
  }

  .uboost-notification {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: flex;
    padding: 6px 16px;
    margin-bottom: 16px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    width: 320px;
    min-height: 50px;
    align-items: center;
    position: relative;

    &.ant-notification-notice-success {
      background-color: rgb(237, 247, 237);
      color: rgb(30, 70, 32);
      border: 1px solid rgba(19, 84, 78, 0.25);
    }

    &.ant-notification-notice-error {
      background-color: rgb(253, 237, 237);
      color: rgb(95, 33, 32);
      border: 1px solid rgba(225, 87, 79, 0.25);
    }

    .ant-notification-notice-close {
      position: absolute;
      top: 8px;
      right: 4px;
    }

    .ant-notification-notice-description {
      margin-inline-start: 32px;
      padding-bottom: 7px;
    }

    svg {
      width: 20px;
      position: relative;
      top: -1px;
    }
  }

  .ant-spin-nested-loading {
    .ant-table-cell {
      height: 55px;
    }

    .ant-skeleton-title {
      margin: 0;
    }
  }

  // modal window component fix for safari
  .ant-modal-root .ant-modal-mask {
    width: 100%;
  }

  .ant-modal-root .ant-modal-wrap {
    width: 100%;
  }

  .ant-modal .ant-modal-close {
    right: 21px;
  }

  &.proposals-page {
    .ant-tooltip {
      .ant-tooltip-inner {
        color: #fff;
        padding: 15px 20px;
      }
    }
  }

  .ant-table {
    overflow-y: auto;
  }

  .popover-link {
    color: $brand-color;
  }
}

.update-policy-modal {
  width: inherit !important;
  max-width: 600px !important;

  @media screen and (max-width: 767px) {
    max-width: 100% !important;
  }

  .ant-modal-confirm-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .anticon {
      display: none;
    }

    .ant-modal-confirm-content {
      margin: 0 !important;
      width: 100%;
      max-width: 100%;
    }
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
  }
}

.discount-info-content {
  .information-block {
    background: #fafafa;
    border: 1px solid #ffeed9;

    &__paragraph {
      b:hover {
        text-decoration: none;
      }
    }

    &__icon {
      height: 32px;
      min-width: 32px;

      & > svg {
        width: 24px;
        height: 24px;
        color: #facb7f;
      }
    }
  }
}
