@import './src/styles';

.analytics {
  $base: &;
  margin-top: 24px;
  
  .analytics-panel {
    margin-top: 24px;
  }
  
  &__profile-stats-filters {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 0 1 auto;
    
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      font-size: 12px;
      height: 26px;
      width: 90px;
      border: 1px solid $profile-stats-filter-background;
      transition: all .3s ease;
      cursor: pointer;
      
      &.active,
      &:hover {
        background: $profile-stats-filter-background;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 100%;
    
    .parameter {
      width: 25%;
    }
  }
  
  &__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    
    .field-select {
      width: auto;
      
      &:nth-child(1) {
        margin-right: 12px;
      }
    }
  }
  
  .bar-chart {
    margin-top: 32px;
    
    .bar-chart-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 450px;
      
      canvas {
        height: 100%;
        width: 100%;
      }
    }
  }
  
  .profile-stats-panel {
    .ant-empty-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0 8px;
      
      svg {
        margin: 0;
      }
    }
    
    .panel__heading {
      padding: 0 0 16px;
      margin: 0;
    }
  }
  
  .chart-panel {
    margin-top: 24px;
    background-color: #fff;
  }
  
  @media (max-width: 1250px) {
    ul {
      margin-top: 16px;
      width: 100%;
    }
  }
  
  @media (max-width: 767px) {
    #{$base}__profile-stats-filters {
      li {
        width: 25%;
      }
    }
  }
  
  @media (max-width: 558px) {
    &__filters {
      margin-top: 8px;
    }
  }
  
  @media (max-width: 480px) {
    padding: 0;
  
    .bar-chart {
      .bar-chart-container {
        min-height: 250px;
        
        .no-chart-data {
          min-height: 250px;
        }
      }
    }
    
    #{$base}__profile-stats-filters {
      li {
        width: 50%;
      }
    }
    
    &__filters {
      width: 100%;
      
      .field-select {
        width: 100%;
        margin: 8px 0 0;
        
        &:nth-child(1) {
          margin: 0;
        }
      }
    }
  }
}