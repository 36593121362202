.login {
  &__container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
  }

  &__content {
    height: 100%;
    padding: 30px 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left-side {
      background: #f5f7fa;
      width: 40%;
    }

    &.right-side {
      width: 60%;

      & > img {
        max-width: 100%;
      }
    }

    .logo {
      position: absolute;
      left: 40px;
      top: 30px;
    }

    @media screen and (max-width: 992px) {
      &.left-side,
      &.right-side {
        width: 50%;
      }
    }

    @media screen and (max-width: 767px) {
      &.left-side {
        width: 100%;
      }

      &.right-side {
        display: none;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  &__title {
    margin: 0;
    font-size: 28px;
    color: #002617;
    font-family: "Inter", sans-serif;

    b {
      font-family: "Montserrat", sans-serif;
      font-weight: 900;
      color: #17b978;
    }
  }

  &__description {
    margin: 0;
    font-size: 14px;
    color: #718ebf;
    margin-bottom: 18px;
    font-family: "Inter", sans-serif;
  }

  &__auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  &__button {
    height: 36px !important;
    width: 280px !important;
    border-radius: 10px !important;
    background: #17b978 !important;
  }

  &__footer {
    margin: 0;
    font-size: 14px;
    color: #718ebf;
    margin-bottom: 18px;
    font-family: "Inter", sans-serif;
  }
}
