@import './src/styles';

.information-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #D9DEDE;
  background: #DEE4E4;
  width: 100%;
  
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    min-width: 24px;
    height: 24px;
    
    img {
      height: 100%;
      width: 100%;
    }
  }
  
  &__paragraph {
    color: #525355;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 0;
    margin: 0;
    width: 100%;
    
    a, b {
      color: $brand-color;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}