@import './src/styles';

.button {
  &.ant-btn {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 90px;
    border: none;
    box-shadow: none;
    background: $btn-background-color;
    color: $secondary-text-color;
    
    .ant-spin {
      top: 2px;
      position: relative;
      margin-left: 8px;
      
      .ant-spin-dot-item {
        background: $online-indicator-color;
      }
    }
  
    &:hover {
      background: $btn-hover-background-color;
    }
  
    &.red {
      background-color: rgba(225, 87, 79, 1);
      
      &:hover {
        background-color: rgba(225, 87, 79, .8);
      }
  
      .ant-spin-dot-item {
        background: $secondary-background-color;
      }
    }
  
    &.green {
      background-color: rgba(19, 84, 78, 1);
      
      &:hover {
        background-color: rgba(19, 84, 78, .8);
      }
    }
  
    &.blue {
      background-color: rgba(44, 111, 174, 1);
      
      &:hover {
        background-color: rgba(44, 111, 174, .8);
      }
  
      .ant-spin-dot-item {
        background: $secondary-background-color;
      }
    }
  
    &:disabled {
      pointer-events: none;
      background-color: $btn-disabled-background-color;
      color: #999;
    }
  }
}